import React, { useContext, useEffect, useState } from 'react';
import { MainContainer } from '../../../components/MainContainer';
import { Dialog } from 'primereact/dialog';
import { FormAddSchedule } from './components/FormAddSchedule';
import { TableSchedule } from './components/TableSchedule';
import { ScheduleNames } from '../../../namesConstants/names';
import { initialStateTable } from '../../Invitations/const';
import useSchedules from '../../../hooks/catalogs/Schedules/useSchedules';
import { StoreContext } from '../../../business/Provider';
import useReload from '../../../hooks/useReload';

import { ToolbarSearch } from './../../../components/ToolbarSearch/index';
import { GeneralNames } from './../../../namesConstants/names';
import useCompanies from '../../../hooks/company/useCompany';

import { downloadFile, getFileNameWithDateTime } from '../../../utils/utils';
import { LOADING_OFF, LOADING_ON } from '../../../business/constants';
import { dataToExcel, getDateHour, getFormattedTimeFromDate } from './utils';
import { useFormik } from 'formik';
import ScheduleObject from './../../../Dto/Catalogs/ScheduleObject';
import ScheduleSchema from './../../../Validations/Catalogs/ScheduleSchema';
import { Toast } from '../../../components/toast';

export const Schedules = () => {
	const { getScheduleState, getScheduleWorkShiftState, dispatchLoading } =
		useContext(StoreContext);
	const { companyInfo } = useCompanies();
	const [schedules, setSchedules] = useState([]);
	const [selectedSchedule, setSelectedSchedule] = useState(undefined);
	const [isEdit, setIsEdit] = useState(false);
	const [openDialogAdd, setOpenDialogAdd] = useState(false);
	const [filters, setFilters] = useState(initialStateTable);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [workShifts, setWorkShifts] = useState([]);
	const { getSchedules, getWorkShift, postSchedules, putSchedules } =
		useSchedules();

	useEffect(() => {
		if (
			getScheduleState.loading === false &&
			getScheduleState.schedules !== undefined &&
			getScheduleState.schedules !== null
		) {
			const _result = getScheduleState.schedules?.results;

			const _resultAddSchedule = _result.map((provider) => {
				let scheduleFormatted =
					provider.starts !== undefined &&
						provider.starts !== null &&
						provider.ends !== undefined &&
						provider.ends !== null
						? `${provider.starts.substring(0, 5)} - ${provider.ends.substring(
							0,
							5
						)}`
						: '';
				const _provider = { ...provider, schedule: scheduleFormatted };
				return _provider;
			});
			setSchedules(_resultAddSchedule);
		}
	}, [getScheduleState]);

	useEffect(() => {
		if (
			getScheduleWorkShiftState.loading === false &&
			getScheduleWorkShiftState.workShifts !== undefined &&
			getScheduleWorkShiftState.workShifts !== null
		) {
			const _result = getScheduleWorkShiftState.workShifts?.results;
			setWorkShifts(_result);
		}
	}, [getScheduleWorkShiftState]);

	useReload([getWorkShift, getSchedules]);

	function onGlobalFilterChange(e) {
		const { value } = e.target;
		const newValue = value.replace(
			'^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
			' '
		);
		const _filters = { ...filters };
		_filters.global.value = newValue;
		setFilters(_filters);
		setGlobalFilterValue(newValue);
	}

	function clearTextLabel() {
		const newValue = ''.replace(
			'^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
			' '
		);
		const _filters = { ...filters };
		_filters.global.value = newValue;
		setGlobalFilterValue(newValue);
		setFilters(_filters);
	}

	function closeDialog() {
		setOpenDialogAdd(false);
		setIsEdit(false);
		setSelectedSchedule(undefined);
	}

	async function onSubmitForm() {
		const {
			code,
			description,
			workShiftDetailId,
			starts,
			ends,
			restDays,
			workShiftId,
		} = formikDataObject.values;

		const result = isEdit
			? await putSchedules(
				{
					workShiftId: workShiftId,
					starts: getFormattedTimeFromDate(starts),
					ends: getFormattedTimeFromDate(ends),
					code: code,
					description: description,
					restDays: restDays,
				},
				workShiftDetailId
			)
			: await postSchedules({
				workShiftId: workShiftId,
				starts: getFormattedTimeFromDate(starts),
				ends: getFormattedTimeFromDate(ends),
				code: code,
				description: description,
				restDays: restDays,
			});

		if (result.status === 200) {
			resetForm();
		}
	}

	const formikSchedulesConfig = {
		initialValues: { ...ScheduleObject },
		validationSchema: ScheduleSchema,
		onSubmit: onSubmitForm,
	};

	const formikDataObject = useFormik(formikSchedulesConfig);

	function resetForm() {
		closeDialog();
		formikDataObject.resetForm();
	}

	const dialogAddSchedules = () => {
		return (
			<Dialog
				header={
					<p className='text-font-size-base text-[#5e5e5e] font-font-family-base font-bold '>
						{`${isEdit ? 'Editar Horario' : 'Añadir Horario'}`}
					</p>
				}
				className='sm:w-[95vw] md:w-[95vw] lg:w-[95vw] xl:w-[75vw] 2xl:w-[50vw]'
				visible={openDialogAdd}
				modal
				style={{ height: '65vh', position: 'relative' }}
				onHide={() => {
					resetForm();
				}}>
				{
					<FormAddSchedule
						onClose={resetForm}
						workShifts={workShifts}
						isEdit={isEdit}
						selectedSchedule={selectedSchedule}
						formikDataObject={formikDataObject}
					/>
				}
			</Dialog>
		);
	};
	function addIconFunction() {
		setOpenDialogAdd(true);
	}

	const exportExcel = async () => {
		if (schedules.length > 0) {
			dispatchLoading({ type: LOADING_ON });
			const toExcel = dataToExcel(schedules, companyInfo);
			const fillName = getFileNameWithDateTime('Horarios');
			try {
				const result = await downloadFile(toExcel, 'xlsx', fillName);
				dispatchLoading({ type: LOADING_OFF });
			} catch (error) {
				dispatchLoading({ type: LOADING_OFF });
			}
		} else {
			Toast('warning', 'No existen registros por exportar');
		}
	};

	const iconsData = [
		{ icon: 'add', title: GeneralNames.GeneralAdd, onClick: addIconFunction },
		{
			icon: 'excel',
			title: GeneralNames.GeneralExportToExcel,
			onClick: exportExcel,
		},
	];

	function handleOnEditSchedule(row) {
		setIsEdit(true);
		setOpenDialogAdd(true);
		const workShiftSelected = workShifts.filter(
			(workShift) => workShift.workShiftId === row.workShiftId
		)[0];

		formikDataObject.setValues({
			...row,
			workShiftType: workShiftSelected?.shiftWorkingDayType,
			hours: workShiftSelected?.hours,
			workShiftName: workShiftSelected?.name,
			starts: getDateHour(row.starts),
			ends: getDateHour(row.ends),
			restDays: row.restDays.split(','),
		});
	}

	const renderHeaderGrid = () => {
		return (
			<div className='w-full flex flex-row !h-[60px]'>
				<ToolbarSearch
					onChange={onGlobalFilterChange}
					placeholder={GeneralNames.GeneralNamesSearchGeneral}
					value={globalFilterValue}
					clearTextLabel={clearTextLabel}
					iconsTable={iconsData}
				/>
			</div>
		);
	};
	const renderBodyMain = () => {
		return (
			<div className='w-full h-full flex flex-col'>
				{renderHeaderGrid()}
				<TableSchedule
					list={schedules}
					filters={filters}
					setOpen={setOpenDialogAdd}
					setEdit={setIsEdit}
					setSelectSchedule={setSelectedSchedule}
					handleOnEditSchedule={handleOnEditSchedule}
				/>
			</div>
		);
	};
	return (
		<MainContainer nameScreen={ScheduleNames.ScreenTitle}>
			{dialogAddSchedules()}
			{renderBodyMain()}
		</MainContainer>
	);
};
